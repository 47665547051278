import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';
import TrainingPlace from 'components/atoms/TrainingPlace';

import 'leaflet/dist/leaflet.css';

const Map = loadable(() => import('components/molecules/Map'));

const Lokalizacje = ({
  data: {
    contentfulLocations: {
      banner: { bannerSlide },
      introText,
      infoText,
      places,
    },
  },
}) => {
  const placesVariant: Variants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.7,
        duration: 0.3,
      },
    },
  };

  return (
    <div className="location">
      <Seo title="Lokalizacje" />
      <Banner slides={bannerSlide} />
      <Container>
        <IntroSection text={introText} justify="center" />
        <motion.div
          className="location__places"
          variants={placesVariant}
          initial="hidden"
          animate="visible"
        >
          {places.map((place: CommonTypes.TrainingPlaceType) => (
            <TrainingPlace key={place.location.lat + place.location.lon} {...place} />
          ))}
        </motion.div>
        <IntroSection text={infoText} justify="center" />
        <Map places={places} />
      </Container>
    </div>
  );
};

export const query = graphql`
  {
    contentfulLocations {
      banner {
        bannerSlide {
          title
          image {
            image {
              gatsbyImageData(formats: WEBP)
            }
            altText
          }
        }
      }
      introText
      infoText
      places {
        district {
          district
        }
        place
        address
        groupTime {
          day
          time
          group {
            group
          }
        }
        location {
          lat
          lon
        }
      }
    }
  }
`;

export default Lokalizacje;
