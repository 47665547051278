import React, { FC } from 'react';

import { TrainingPlaceProps } from './models.d';

const TrainingPlace: FC<TrainingPlaceProps> = ({ place, address, district, groupTime }) => {
  const groupTrainings = (group: CommonTypes.GroupTimeType[]) => {
    const days = Array.from(new Set(group.map((row) => row.day[0])));

    const grouped = days.map((day) => {
      const grouptime = groupTime
        .filter((item) => item.day.includes(day))
        .map((item) => ({ time: item.time, group: item.group.group }));
      return { day, grouptime };
    });
    return grouped;
  };

  groupTrainings(groupTime);

  return (
    <div className="training-place">
      <div className="training-place__name">{place}</div>
      <div className="training-place__district">{district.district}</div>
      <div className="training-place__address">{address}</div>
      <div className="training-place__grouptime-wrapper">
        {groupTrainings(groupTime).map((item) => (
          <div className="training-place__groupday">
            <p className="training-place__day">{item.day}</p>
            {item.grouptime.map((gt) => (
              <div key={gt.time + gt.group} className="training-place__grouptime">
                <span className="training-place__time">{gt.time}</span>
                <span className="training-place__group">{gt.group}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingPlace;
